import { reactive } from '@vue/composition-api';
import {
  SubscriptionPlanStatusType,
  usePayment,
  SubscriptionPlan,
  SubscriptionProduct,
  SubscriptionPlanProps,
  SUBSCRIPTION_PLAN_STATUS,
} from '@/admin/payment';
import { DisplayDate } from './util';

const {
  getSubscriptionPlans,
  getSubscriptionProducts,
  getSubscriptionPlan,
  saveSubscriptionPlan,
  saveSubscriptionProduct,
  deleteSubscriptionPlan,
  createScheduleForArchiveSubscriptionPlan,
  deleteScheduleForArchiveSubscriptionPlan,
} = usePayment();

const intervalOptions = {
  day: '日',
  month: 'ヶ月',
  year: '年',
};
export function getIntervalText(interval: 'day' | 'month' | 'year') {
  return intervalOptions[interval];
}
interface PlansProperty {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  subscriptionPlanStatus: SubscriptionPlanStatusType;
  subscriptionProductId: string;
  subscriptionProductName?: string;
  priceId: string;
  price: number;
  interval: 'day' | 'month' | 'year';
  intervalCount: number;
  description: string;
  counterPriorities: string[];
  order: number;
  isArchive: boolean;
  initialSettings?: {
    priceId: string;
    price: number;
  };
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  scheduledArchiveDate: DisplayDate;
  archiveDate: DisplayDate;
  subscriptionCampaigns: SubscriptionPlan['subscriptionCampaigns'];
  isRequiredShippingAddress: boolean;
  maxSubscribersCount?: number;
  isEnrollmentStopped: boolean;
}

export interface PlanProperty {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  subscriptionPlanStatus: SubscriptionPlanStatusType;
  subscriptionProductId: string;
  price: number;
  description: string;
  counterPriorities: string[];
  order: number;
  initialPrice?: number | string;
  interval: 'day' | 'month' | 'year';
  intervalCount: number;
  isArchive?: boolean;
  accessStartDate: DisplayDate;
  accessEndDate: DisplayDate;
  scheduledArchiveDate: DisplayDate;
  archiveDate: DisplayDate;
  subscriptionCampaigns: SubscriptionPlan['subscriptionCampaigns'];
  isRequiredShippingAddress: boolean;
  maxSubscribersCount?: number;
  isEnrollmentStopped?: boolean; // NOTE: 取得の際はundefinedであることはないが、加入者数上限フラグがOFFの場合はこの値をリクエストに含めないため、undefinedを許容する
  currentSubscribersCount?: number;
  reservedSubscribersCount?: number;
}

interface ProductProps {
  subscriptionProductName: string;
}

export class Plans {
  fetchLoading = false;
  items: PlansProperty[] = [];
  isSaving = false;

  constructor() {
    this.init();
  }

  init = async (refresh?: boolean) => {
    this.fetchLoading = true;
    const [plans, products] = await Promise.all([getSubscriptionPlans(refresh), getSubscriptionProducts()]);
    // 商品ID一覧：プランのorder順に基づいた商品IDの配列作成
    const subscriptionProductIds = plans
      .map((plan) => plan.subscriptionProductId)
      .filter((id, index, subscriptionProductIds) => index === subscriptionProductIds.indexOf(id));
    // プラン一覧に表示する配列の作成
    this.items = subscriptionProductIds.reduce((acc_plans, val_productId) => {
      // 商品名
      const subscriptionProductName =
        products.find((product) => val_productId === product.subscriptionProductId)?.subscriptionProductName || '';
      if (subscriptionProductName) {
        const productPlans = plans
          .filter((plan) => val_productId === plan.subscriptionProductId)
          .map((plan) => {
            const item: PlansProperty = {
              subscriptionPlanId: plan.subscriptionPlanId,
              subscriptionPlanName: plan.subscriptionPlanName,
              subscriptionPlanStatus: plan.subscriptionPlanStatus,
              subscriptionProductId: plan.subscriptionProductId,
              subscriptionProductName,
              priceId: plan.priceId,
              price: plan.price,
              interval: plan.interval,
              intervalCount: plan.intervalCount,
              isArchive: plan.isArchive,
              description: plan.description,
              counterPriorities: plan.counterPriorities,
              order: plan.order,
              initialSettings: plan.initialSettings,
              accessStartDate: new DisplayDate(plan.accessStartDate),
              accessEndDate: new DisplayDate(plan.accessEndDate),
              scheduledArchiveDate: new DisplayDate(plan.scheduledArchiveDate),
              archiveDate: new DisplayDate(plan.archiveDate),
              subscriptionCampaigns: plan.subscriptionCampaigns,
              isRequiredShippingAddress: plan.isRequiredShippingAddress,
              maxSubscribersCount: plan.maxSubscribersCount,
              isEnrollmentStopped: plan.isEnrollmentStopped,
            };
            return item;
          });
        acc_plans.push(...productPlans);
      }
      return acc_plans;
    }, [] as PlansProperty[]);
    this.fetchLoading = false;
  };

  delete = async (id: string) => {
    await deleteSubscriptionPlan(id);
    this.items = this.items.filter((item) => id !== item.subscriptionPlanId);
  };
  createScheduleForArchive = async (subscriptionPlanId: string, archiveDate: number) => {
    await createScheduleForArchiveSubscriptionPlan(subscriptionPlanId, archiveDate);
  };
  deleteScheduleForArchive = async (subscriptionPlanId: string) => {
    await deleteScheduleForArchiveSubscriptionPlan(subscriptionPlanId);
  };
}

export class Plan {
  planId = '';
  fetchLoading = false;
  isSaving = false;
  isAccessEndDate = false;
  isNoSubscriptions = false;
  item: PlanProperty = {
    subscriptionPlanId: '',
    subscriptionPlanName: '',
    subscriptionProductId: '',
    subscriptionPlanStatus: SUBSCRIPTION_PLAN_STATUS.DURING,
    price: 0,
    initialPrice: undefined,
    description: '',
    counterPriorities: ['DefaultUserNumber'],
    order: 0,
    interval: 'month',
    intervalCount: 1,
    accessStartDate: new DisplayDate(),
    accessEndDate: new DisplayDate(),
    scheduledArchiveDate: new DisplayDate(),
    archiveDate: new DisplayDate(),
    subscriptionCampaigns: [],
    isRequiredShippingAddress: false,
    maxSubscribersCount: undefined,
    isEnrollmentStopped: false,
    currentSubscribersCount: undefined,
    reservedSubscribersCount: undefined,
  };
  productNames: { value: string; text: string }[] = [];
  memberCounters: { value: string; text: string }[] = [];
  subscriptionPlans: SubscriptionPlan[] = [];
  subscriptionPlanIds: string[] = [];

  constructor(planId?: string) {
    if (planId) this.planId = planId;
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    const [_products, _plan] = await Promise.all([
      getSubscriptionProducts(),
      this.planId ? getSubscriptionPlan(this.planId) : null,
    ]);
    this.productNames = _products.map((product) => {
      return {
        value: product.subscriptionProductId,
        text: product.subscriptionProductName,
      };
    });
    this.subscriptionPlans = await getSubscriptionPlans();
    this.subscriptionPlanIds = this.subscriptionPlans.map((plan) => plan.subscriptionPlanId);
    if (_plan) {
      this.isNoSubscriptions = _plan.isNoSubscriptions;
      this.item.subscriptionPlanId = _plan.subscriptionPlanId;
      this.item.subscriptionPlanName = _plan.subscriptionPlanName;
      this.item.subscriptionProductId = _plan.subscriptionProductId;
      this.item.subscriptionPlanStatus = _plan.subscriptionPlanStatus;
      this.item.price = _plan.price;
      this.item.description = _plan.description;
      this.item.counterPriorities = _plan.counterPriorities;
      this.item.order = _plan.order;
      this.item.isArchive = _plan.isArchive;
      this.item.interval = _plan.interval;
      this.item.intervalCount = _plan.intervalCount;
      this.item.initialPrice = _plan.initialSettings?.price;
      this.item.accessStartDate = _plan.accessStartDate
        ? new DisplayDate(_plan.accessStartDate)
        : this.item.accessStartDate;
      if (_plan.accessEndDate) {
        this.isAccessEndDate = true;
        this.item.accessEndDate = new DisplayDate(_plan.accessEndDate);
      }
      this.item.scheduledArchiveDate = new DisplayDate(_plan.scheduledArchiveDate);
      this.item.archiveDate = new DisplayDate(_plan.archiveDate);
      this.item.isRequiredShippingAddress = _plan.isRequiredShippingAddress;
      this.item.maxSubscribersCount = _plan.maxSubscribersCount;
      this.item.isEnrollmentStopped = _plan.isEnrollmentStopped;
      this.item.currentSubscribersCount = _plan.currentSubscribersCount;
      this.item.reservedSubscribersCount = _plan.reservedSubscribersCount;
    }
    this.fetchLoading = false;
  };

  createPlan = async () => {
    this.validate();
    // プラン作成時のみ価格のバリデーションを行う
    if (this.item.price !== 0 && this.item.price < 50) throw '1円以上50円未満は設定できません';
    if (this.item.accessStartDate.value === undefined) throw '開始日で未入力があります';

    const props: SubscriptionPlanProps = {
      subscriptionPlanId: this.item.subscriptionPlanId,
      subscriptionPlanName: this.item.subscriptionPlanName,
      subscriptionProductId: this.item.subscriptionProductId,
      price: this.item.price,
      description: this.item.description,
      counterPriorities: this.item.counterPriorities,
      interval: this.item.interval,
      intervalCount: this.item.intervalCount,
      initialPrice: this.item.initialPrice === '' ? undefined : this.item.initialPrice,
      accessStartDate: this.item.accessStartDate.value,
      isRequiredShippingAddress: this.item.isRequiredShippingAddress,
      maxSubscribersCount: this.item.maxSubscribersCount,
      isEnrollmentStopped: this.item.isEnrollmentStopped,
    };
    if (this.isAccessEndDate && this.item.accessEndDate.value) {
      props.accessEndDate = this.item.accessEndDate.value;
    }
    await saveSubscriptionPlan(props);
  };

  savePlan = async (planId: string) => {
    this.validate();
    if (this.item.accessStartDate.value === undefined) throw '開始日で未入力があります';
    this.isSaving = true;

    const props: SubscriptionPlanProps = {
      subscriptionPlanId: this.item.subscriptionPlanId,
      subscriptionPlanName: this.item.subscriptionPlanName,
      subscriptionProductId: this.item.subscriptionProductId,
      price: this.item.price,
      description: this.item.description,
      counterPriorities: this.item.counterPriorities,
      order: this.item.order,
      interval: this.item.interval,
      intervalCount: this.item.intervalCount,
      initialPrice: this.item.initialPrice === '' ? undefined : this.item.initialPrice,
      accessStartDate: this.item.accessStartDate.value,
      isRequiredShippingAddress: this.item.isRequiredShippingAddress,
      maxSubscribersCount: this.item.maxSubscribersCount,
      isEnrollmentStopped: this.item.isEnrollmentStopped,
    };
    if (this.isAccessEndDate && this.item.accessEndDate.value) {
      props.accessEndDate = this.item.accessEndDate.value;
    }
    await saveSubscriptionPlan(props, planId);
    this.isSaving = false;
  };

  validate = () => {
    if (
      !this.item.counterPriorities ||
      !this.item.subscriptionProductId ||
      !this.item.subscriptionPlanId ||
      !this.item.subscriptionPlanName ||
      !this.item.intervalCount ||
      this.item.accessStartDate.value === undefined ||
      (this.item.price !== 0 && !this.item.price)
    )
      throw '商品ID，プランID（半角英数字），プラン名，期間，料金，閲覧開始日で未入力があります';
    else if (!this.planId && this.subscriptionPlanIds.includes(this.item.subscriptionPlanId))
      throw 'プランID（半角英数字）が重複しています。';
    else if (!this.planId && this.item.subscriptionPlanId === 'freePlan')
      throw 'プランIDに[freePlan]は使用できません。';
    else if (!this.item.subscriptionPlanId.match(/[a-zA-Z0-9!-/:-@¥[-`{-~]$/))
      throw 'プランID（半角英数字）は大文字，小文字，数字の半角英数字，ハイフン・アンダースコアで設定してください';
    // 期間のvalidate
    else if (this.item.intervalCount > 365) throw '366日以上を設定できません。';
    else if (this.item.interval === 'month' && this.item.intervalCount > 12) throw '13ヶ月以上を設定できません。';
    else if (this.item.interval === 'year' && this.item.intervalCount > 1) throw '2年以上を設定できません。';
    else if (this.isAccessEndDate && this.item.accessEndDate.value === undefined) {
      throw '不正な日時です';
    } else if (this.item.maxSubscribersCount !== undefined && this.item.maxSubscribersCount < 1) {
      throw '加入者数上限値は1以上で設定してください';
    }
  };
}

export class Product {
  fetchLoading = false;
  isSaving = false;
  item: ProductProps = {
    subscriptionProductName: '',
  };
  items: SubscriptionProduct[] = [];

  constructor() {
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    this.items = await getSubscriptionProducts();
    this.fetchLoading = false;
  };

  saveProduct = async (productId?: string) => {
    const props = {
      subscriptionProductName: this.item.subscriptionProductName,
    };
    await saveSubscriptionProduct(props, productId);
  };

  createProduct = async () => {
    const props = {
      subscriptionProductName: this.item.subscriptionProductName,
    };
    await saveSubscriptionProduct(props);
  };

  validate = () => {
    let result = {
      isValid: true,
      errorMessage: '',
    };
    if (!this.item.subscriptionProductName) result = { isValid: false, errorMessage: '商品名を入力してください。' };
    return result;
  };
}

export const usePlans = () => {
  const plans = new Plans();
  return { plans: reactive(plans) };
};

export const usePlan = (planId?: string) => {
  const plan = new Plan(planId);
  return { plan: reactive(plan) };
};

export const useProduct = () => {
  const product = new Product();
  return { product: reactive(product) };
};
