





























































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { useProduct } from '@/admin/plan';
import { usePayment } from '@/admin/payment';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'PlanProducts',
  components: {
    FcRoleLoading,
  },
  props: {},
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('plans'));

    const { product } = useProduct();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();

    const headers = [{ text: 'グループ名', value: 'subscriptionProductName' }];

    const active = ref(false);
    const productId: Ref<string | undefined> = ref('');
    const cancel = () => {
      active.value = false;
    };

    const open = (id?: string, name?: string) => {
      active.value = true;
      product.item.subscriptionProductName = name || '';
      productId.value = id || '';
    };

    const save = async () => {
      const { isValid, errorMessage } = product.validate();
      if (!isValid) {
        notification.error(errorMessage);
        return;
      }
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      product.isSaving = true;
      try {
        if (productId.value) {
          await product.saveProduct(productId.value);
          notification.notify('変更しました。');
        } else {
          await product.createProduct();
          notification.notify('作成しました。');
        }
        product.items = await usePayment().getSubscriptionProducts();
      } catch (error) {
        notification.error(error);
      }
      active.value = false;
      product.isSaving = false;
    };

    return {
      pageTitle: 'グループ作成',
      myRoleSettings,
      product,
      productId,
      save,
      headers,
      active,
      cancel,
      open,
    };
  },
});
